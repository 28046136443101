import React from 'react';
import * as apiToken from '../../utils/api-token';
import {useNavigate} from "react-router-dom";

export const useEntrypoint = () => {
  const [authorized, setAuthorized] = React.useState(undefined);
  const nav = useNavigate()

  const init = async () => {
    const token = apiToken.getToken();
    setAuthorized(Boolean(token));
  };

  React.useEffect(() => {
    init();
  }, []);

  const onLogin = (token) => {
    apiToken.setToken(token);
    setAuthorized(true);
    nav("/clients")
  };

  return {
    authorized,
    onLogin,
  };
};
