import React from 'react';
import { SnackbarProvider } from 'notistack';
import CustomNotify from './CustomNotify';

const Snackbar = ({ children }) => {
  const ref = React.useRef();

  return (
    <SnackbarProvider
      ref={ref}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      content={(key, message) => {
        const snacks = ref.current.state.snacks;

        const snack = snacks.find((item) => item.key === key);

        return (
          <CustomNotify
            id={key}
            message={message}
            variant={snack && snack.variant ? snack.variant : 1}
          />
        );
      }}>
      {children}
    </SnackbarProvider>
  );
};

export default Snackbar;
