import React from 'react';

export const UseRequest = () => {
  const [loading, setLoading] = React.useState(false);

  const refControllers = React.useRef([]);

  React.useEffect(() => {
    const controllers = refControllers.current;
    return () => {
      controllers.forEach((item) => {
        if (item && item.abort) {
          item.abort();
        }
      });
    };
  },[]);

  const addController = (item) => refControllers.current.push(item);

  return {
    loading,
    setLoading,
    addController,
  }
};
