export const THEME = {
  color: {
    dark: '#1A1919',
    red: '#AB2430',
    error: '#D42A2A',
    blue: '#286AA6',
    white: '#F1F8FF',
    green: '#439343',
  },
  containerPadding: {
    x: '36px',
  },
  zIndex: {
    loader: 901,
    dropdown: 3333,
    sidebar: 900,
    toolbar: 800
  }
};
