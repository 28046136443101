import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: false,
  loading: false,
  content: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action) => {
      document.body.style.overflow = 'hidden';
      state.visible = true;
      state.content = action.payload;
    },
    hideModal: (state) => {
      document.body.style.overflow = 'unset';
      state.visible = false;
    },
  },
});

export const modalActions = modalSlice.actions;

export default modalSlice.reducer;
