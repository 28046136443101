import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoSvg } from '../../../assets/icons/logo.svg';
import { MENU_LINKS } from '../../../constants/menu';
import { NavLink } from 'react-router-dom';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 276px;
  height: 100%;
  background-color: #fff;
  z-index: ${({ theme }) => theme.zIndex.sidebar};
  border-radius: 0px 35px 35px 0px;
  box-shadow: 0px 0px 15px rgba(40, 106, 166, 0.15);
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 33px 0 48px 0;
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  & .active-link {
    background-color: ${({ theme }) => theme.color.red};
    color: #fff;
    font-weight: 600;

    & svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

const MenuLink = styled(NavLink)`
  color: ${({ active, theme }) => theme.color.dark};
  font-weight: ${({ active, theme }) => (active ? 700 : 500)};
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 15px 18px;
  background-color: transparent;
  cursor: pointer;
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 16px;
`;

const Sidebar = () => {
  return (
    <Nav>
      <Logo>
        <LogoSvg />
      </Logo>
      <List>
        {MENU_LINKS.map((link) => (
          <MenuLink key={link.id} to={link.path} activeclassname="active-link">
            <Icon>{link.icon}</Icon> <span>{link.label}</span>
          </MenuLink>
        ))}
      </List>
    </Nav>
  );
};

export default React.memo(Sidebar);
