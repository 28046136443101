import React from 'react';
import { createId } from '../utils/id-generator';
import { ReactComponent as ClientsSvg } from '../assets/icons/clients.svg';
import { ReactComponent as OrdersSvg } from '../assets/icons/orders.svg';
import {
  ReactComponent as DeliveriesSvg,
} from '../assets/icons/deliveries.svg';
import { ReactComponent as PromoSvg } from '../assets/icons/promocodes.svg';
import { ReactComponent as BellSvg } from '../assets/icons/bell.svg';
import { ReactComponent as ServiceMenegment } from '../assets/icons/service-menegment.svg';

export const MENU_LINKS = [
  {
    id: createId(),
    icon: <ClientsSvg/>,
    path: '/clients',
    label: 'Клиенты',
  },
  {
    id: createId(),
    icon: <OrdersSvg/>,
    path: '/orders',
    label: 'Заказы',
  },
  {
    id: createId(),
    icon: <DeliveriesSvg/>,
    path: '/deliveries',
    label: 'Доставки',
  },
  {
    id: createId(),
    icon: <PromoSvg/>,
    path: '/promocode',
    label: 'Промокоды',
  },
  {
    id: createId(),
    icon: <BellSvg/>,
    path: '/push/persistent',
    label: 'Push Уведомления',
  },
  {
    id: createId(),
    icon: <ServiceMenegment/>,
    path: '/services',
    label: 'Управление услугами',
  },
];
