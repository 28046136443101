import styled from 'styled-components';

export const CommonInput = styled.input`
  border: none;
  background-color: transparent;
  width: 100%;
  outline: none;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'ProximaNova', 'sans-serif', sans-serif;
  font-weight: 500;
`;

export const LightInput = styled(CommonInput)`
  color: ${({theme, disabled}) => disabled
          ? 'rgba(0, 0, 0, 0.38)'
          : theme.color.dark};
  height: ${({height}) => height || '45px'};
  width: ${({width}) => width || '100%'};
  background-color: ${({theme, invert}) => invert
          ? '#ffffff'
          : theme.color.white};
  border-radius: 6px;
  padding: ${({isPush}) => isPush
          ? '0 35px 0 14px'
          : '0 14px'};
  border-width: 1px;
  border-style: solid;
  border-color: ${(({theme, invalid, success}) => {
    if (invalid) {
      return theme.color.error;
    }

    if (success) {
      return theme.color.green;
    }

    return 'transparent';
  })};

  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type='number'],
  &[type="number"]:hover,
  &[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
`;
