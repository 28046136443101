import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Account from '../Account/Account';
import Login from '../../pages/Login/Login';
import Loader from '../../components/Loader';
import {useEntrypoint} from './useEntrypoint';

const Entrypoint = () => {
  const { authorized, onLogin } = useEntrypoint();

  if (authorized === undefined) {
    return <Loader visible />;
  }

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login onLogin={onLogin} />} />
        <Route path="*" element={<Account />} />
      </Routes>

      {authorized === false && <Navigate to={'/login'} />}
    </>
  );
};

export default Entrypoint;
