import { UseRequest } from '../../hooks/useRequest';
import React from 'react';
import { post } from '../../api';

const ERRORS = {
  EMPTY: 'Необходимо заполнить все поля',
  SERVER: 'Ошибка авторизации, попробуйте еще раз',
};

export const useLogin = (onLogin) => {
  const { loading, setLoading, addController } = UseRequest();

  const [loginForm, setLoginForm] = React.useState({ username: '', password: '' });

  const [errorMessage, setErrorMessage] = React.useState(null);

  const login = async (body) => {
    try {
      setLoading(true);

      const { response, controller } = await post('/admin/login/', body);

      addController(controller);

      if (response.token) {
        onLogin(response.token);
      }
    } catch (err) {
      setErrorMessage(err.message || ERRORS.SERVER);
    } finally {
      setLoading(false);
    }
  };

  const validate = ({ username, password }) =>
    username.trim().length > 3 && password.trim().length > 5;

  const onChangeForm = React.useCallback((key) => {
    return ({ target }) => setLoginForm((prevState) => ({ ...prevState, [key]: target.value }));
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    setErrorMessage(null);

    if (validate(loginForm)) {
      login(loginForm);
    } else {
      setErrorMessage(ERRORS.EMPTY);
    }
  };

  const onFieldFocus = () => {
    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  return {
    loading,
    loginForm,
    errorMessage,
    onChangeForm,
    onSubmit,
    onFieldFocus,
  };
};
