import styled from 'styled-components';

export const CommonButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
`;

export const BlueButton = styled(CommonButton)`
  background-color: ${({ theme }) => theme.color.blue};
  border-radius: 7px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '36px'};
  margin: ${({ margin }) => margin};
`;

export const CreateButton = styled(CommonButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #ab2430;
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '36px'};
  margin: ${({ margin }) => margin};
`;

const DialogCommonButton = styled(CommonButton)`
  border-radius: 6px;
  width: ${({ width }) => width || '50%'};
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 0;
  font-weight: 600;
  font-family: 'ProximaNova';
`;

export const DeleteButton = styled(DialogCommonButton)`
  background-color: ${({ theme }) => theme.color.red};
`;
export const DeletePushButton = styled(DialogCommonButton)`
  background-color: #286aa6;
  color: #f1f8ff;
`;

export const CancelButton = styled(DialogCommonButton)`
  background: rgba(40, 106, 166, 0.38);
`;
export const CancelPushButton = styled(DialogCommonButton)`
  background-color: #f1f8ff;
  color: #286aa6;
`;

export const SaveButton = styled(DialogCommonButton)`
  background-color: ${(props) => (!props.disabled ? `#286AA6` : 'grey')};
  cursor: ${(props) => (!props.disabled ? `pointer` : 'not-allowed')};
`;

export const SavePushButton = styled(DialogCommonButton)`
  background-color: ${(props) => (!props.disabled ? `#286AA6` : 'grey')};
  width: 100%;
  height: 40px;
  cursor: ${(props) => (!props.disabled ? `pointer` : 'not-allowed')};
`;
