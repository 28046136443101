import React from "react";
import styled from 'styled-components';
import {
  BlueButton,
  CommonInput,
} from "../../styled-elements";
import Loader from "../../components/Loader";
import { useLogin } from "./useLogin";

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = styled.article`
  position: relative;
  width: 390px;
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 10px;

  & form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  color: ${ ({ theme }) => theme.color.blue };
  margin: 0;
  font-weight: 900;
  text-align: center;
`;

const Input = styled(CommonInput)`
  border-bottom: 1px solid ${ ({ theme, invalid }) => invalid ? theme.color.error : theme.color.dark };
  color: ${ ({ theme }) => theme.color.dark };
  padding: 10px 0;
  margin-bottom: ${ ({ first }) => first ? '38px' : '0' };

  &::placeholder {
    color: ${ ({ theme, invalid }) => invalid ? theme.color.error : theme.color.dark };
  }
`;

const InputsWrapper = styled.div`
  padding-top: 68px;
  padding-bottom: 25px;
`;

const ErrorField = styled.p`
  color: ${ ({ theme }) => theme.color.error };
  font-size: 0.875rem;
  min-height: 20px;
  margin-top: 30px;
  margin-bottom: 0;
`;

const Login = ({ onLogin }) => {

  const {
    loginForm,
    loading,
    errorMessage,
    onSubmit,
    onChangeForm,
    onFieldFocus
  } = useLogin(onLogin);

  return (
    <Root>
      <Card>
        <Loader visible={ loading }/>
        <form onSubmit={ onSubmit }>
          <Title>Вход</Title>
          <InputsWrapper>
            <Input
              first
              invalid={ errorMessage && loginForm.username.trim().length === 0 }
              type="text"
              placeholder="Логин"
              value={ loginForm.username }
              onChange={ onChangeForm('username') }
              onFocus={ onFieldFocus }
            />
            <Input
              invalid={ errorMessage && loginForm.password.trim().length === 0 }
              type="password"
              placeholder="Пароль"
              value={ loginForm.password }
              onChange={ onChangeForm('password') }
              onFocus={ onFieldFocus }
            />
          </InputsWrapper>
          <BlueButton width="88px" type="submit">Войти</BlueButton>
          <ErrorField>{ errorMessage }</ErrorField>
        </form>
      </Card>
    </Root>
  )
};

export default Login;
