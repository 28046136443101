import styled from 'styled-components';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Root = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ transparent }) =>
    transparent ? 'transparent' : 'rgba(255, 255, 255, 0.6)'};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.loader};

  & .MuiCircularProgress-svg {
    color: ${({ theme }) => theme.color.blue};
  }
`;

const Loader = ({ visible, transparent }) => {
  if (!visible) {
    return null;
  }

  return (
    <Root transparent={transparent}>
      <div>
        <CircularProgress />
      </div>
    </Root>
  );
};

const memoized = React.memo(Loader);
export { memoized };
