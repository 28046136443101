import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { THEME } from './theme';
import Entrypoint from './containers/Entrypoint/Entrypoint';
import Snackbar from './components/Snackbar/Snackbar';
import { get } from './api';
import { useSelector } from 'react-redux';
import {BrowserRouter} from "react-router-dom";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.color.white}
  }
`;

const ModalLazy = React.lazy(() => import('./components/Modal/Modal'));

const App = () => {
  const modalVisible = useSelector((state) => state.modal.visible);

  React.useEffect(() => {
    get('/admin/refresh_session/');
  }, []);

  return (
    <ThemeProvider theme={THEME}>
      <Snackbar>
        <React.Fragment>
          <React.Suspense fallback="">
            {modalVisible && <ModalLazy />}
            <GlobalStyle />
            <BrowserRouter>
              <Entrypoint />
            </BrowserRouter>
          </React.Suspense>
        </React.Fragment>
      </Snackbar>
    </ThemeProvider>
  );
};

export default App;
