import React from "react";
import { get } from "../api";

export const PriceListContext = React.createContext([]);

export const usePriceList = (excludesIds = null) => {
  const [services, setServices] = React.useState([]);

  const getServiceData = async () => {
    try {
      const { response } = await get('/orders/services/pricelist/');
      setServices(response);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    getServiceData();
  }, []);

  return services
}

export const usePriceListContext = () => React.useContext(PriceListContext);
