import React from 'react';
import styled from 'styled-components';
import Toolbar from './components/Toolbar';
import Sidebar from './components/Sidebar';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: calc(${({ theme }) => theme.containerPadding.x} + 65px)
    ${({ theme }) => theme.containerPadding.x} ${({ theme }) => theme.containerPadding.x}
    calc(${({ theme }) => theme.containerPadding.x} + 276px);
`;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Layout = ({ children }) => {
  return (
    <Root>
      <Sidebar />
      <Toolbar />
      <Content>{children}</Content>
    </Root>
  );
};

export default React.memo(Layout);
