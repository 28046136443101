import React from 'react';
import styled from "styled-components";
import { useSnackbar } from "notistack";

const Root = styled.div`
  width: 300px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 15px rgba(40, 106, 166, 0.15);
  color: ${ ({ theme, variant }) => {
    if (variant === 'success') {
      return theme.color.blue;
    }

    if (variant === 'error') {
      return theme.color.error;
    }
  } };
`;

const Text = styled.h4`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 20px 10px;
  text-align: center;
`;

const Control = styled.h4`
  border-top: 1px solid #F1F8FF;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;

  &:hover {
    background-color: #F1F8FF;
  }
`;

const CustomNotify = React.forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  const onClose = () => closeSnackbar(props.id);

  return (
    <Root ref={ ref } variant={props.variant}>
      <Text dangerouslySetInnerHTML={ { __html: props.message } }/>
      <Control onClick={onClose}>
        OK
      </Control>
    </Root>
  )
});

export default CustomNotify;
