import React from 'react';
import {
  PriceListContext,
  usePriceList
} from "../../hooks/usePricelist";

const CommonDataProvider = ({ children }) => {

  const priceList = usePriceList();

  return (
    <PriceListContext.Provider value={ priceList }>
      { children }
    </PriceListContext.Provider>
  )
};

export default CommonDataProvider;
