import React from "react";

export const UserContext = React.createContext({
  user: undefined,
});

export const BackdropLoadingContext = React.createContext({
  visible: false,
  setVisibility: () => {},
})
