import React from 'react';
import Layout from '../Layout/Layout';
import { Route, Routes } from 'react-router-dom';
import Loader from '../../components/Loader';
import CommonDataProvider from '../CommonDataProvider/CommonDataProvider';

const ClientsPage = React.lazy(() => import('../../pages/Clients/Clients'));
const OrdersPage = React.lazy(() => import('../../pages/Orders/Orders'));
const ClientsDetailPage = React.lazy(
  () => import('../../pages/ClientsDetail/ClientsDetail'));
const OrderCreatePage = React.lazy(
  () => import('../../pages/OrderCreate/OrderCreate'));
const OrderDetailPage = React.lazy(
  () => import('../../pages/OrderDetail/OrderDetail'));
const DeliveriesPage = React.lazy(
  () => import('../../pages/Deliveries/Deliveries'));
const DeliveryCreatePage = React.lazy(
  () => import('../../pages/DeliveryCreate/DeliveryCreate'));
const DeliveryDetailPage = React.lazy(
  () => import('../../pages/DeliveryDetail/DeliveryDetail'));
const PromoCodePage = React.lazy(
  () => import('../../pages/Promocodes/Promocodes'));
const PromoCodeCreatePage = React.lazy(
  () => import('../../pages/PromocodeCreate/PromocodeCreate'));
const PromoCodeHistoryPage = React.lazy(() =>
  import('../../pages/PromocodesHistory/PromocodesHistory'),
);
const PersistentPushPage = React.lazy(
  () => import('../../pages/PersistentPush/PersistentPush'));
const OneTimePushPage = React.lazy(
  () => import('../../pages/OneTimePush/OneTimePush'));
const OneTimePushHistoryPage = React.lazy(
  () => import('../../pages/OneTimePushHistory/OneTimePushHistory'));
const ServicesPage = React.lazy(
  () => import('../../pages/Services/Services'));
const Account = () => (
  <Layout>
    <CommonDataProvider>
      <Routes>
        <Route
          path={`/clients`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <ClientsPage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/clients/:clientId`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <ClientsDetailPage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/orders`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <OrdersPage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/orders/create`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <OrderCreatePage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/orders/view/:orderId`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <OrderDetailPage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/deliveries`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <DeliveriesPage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/deliveries/create`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <DeliveryCreatePage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/deliveries/view/:id`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <DeliveryDetailPage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/promocode`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <PromoCodePage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/promocode/create`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <PromoCodeCreatePage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/promocode/history`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <PromoCodeHistoryPage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/promocode/edit`}
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <PromoCodeCreatePage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/push/persistent`}
          exact
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <PersistentPushPage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/push/one-time`}
          exact
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <OneTimePushPage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/push/history`}
          exact
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <OneTimePushHistoryPage/>
            </React.Suspense>
          }
        />
        <Route
          path={`/services`}
          exact
          element={
            <React.Suspense fallback={<Loader visible/>}>
              <ServicesPage/>
            </React.Suspense>
          }
        />
      </Routes>
    </CommonDataProvider>
  </Layout>
);

export default Account;
