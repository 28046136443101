import React from "react";
import styled from "styled-components";
import { UserContext } from "../../../context";

const Root = styled.header`
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${ ({ theme }) => theme.color.blue };
  padding: 0 ${ ({ theme }) => theme.containerPadding.x };
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${({theme}) => theme.zIndex.toolbar};
  box-shadow: 0px 4px 4px rgba(40, 106, 166, 0.15);
`;

const Avatar = styled.div`
  background-color: #fff;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  cursor: pointer;
`;

const Toolbar = () => {

  const { user } = React.useContext(UserContext);

  return (
    <Root>
      { user && <Avatar title={user}/> }
    </Root>
  );
};

export default React.memo(Toolbar);
