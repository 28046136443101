import * as apiToken from '../utils/api-token';

const request = (url, params, controller) => {
  // https://penguin.sixhands.co
  return fetch(`/api${url}`, params)
    .then((res) => {
      if (!res.ok) {
        return res.text().then((text) => {
          throw JSON.parse(text);
        });
      }

      if (res.status !== 204) {
        try {
          return res.json();
        } catch (error) {
          console.log(error);
        }
      }
    })
    .then((res) => {
      if (res && res.error && res.error === 1) {
        throw Error(res.Msg);
      }
      return res;
    })
    .then((response) => ({ response, controller }));
};

const http = async (url, method, body) => {
  const controller = new AbortController();

  const isFormData = body instanceof FormData;

  const params = {
    method,
    signal: controller.signal,
    headers: isFormData ? new Headers() : new Headers({ 'content-type': 'application/json' }),
  };

  if (body) {
    params.body = body instanceof FormData ? body : JSON.stringify(body);
  }

  // const token = '9b8be2d25ff1d5a920acdfec12637f11ea89cc1e';
  const token = apiToken.getToken();

  if (token) {
    params.headers.set('Authorization', `Token ${token}`);
  }

  return request(url, params, controller);
};

export const get = (url) => http(url, 'GET');

export const post = (url, body) => http(url, 'POST', body);

export const put = (url, body) => http(url, 'PUT', body);

export const patch = (url, body) => http(url, 'PATCH', body);

export const deleteReq = (url) => http(url, 'DELETE');
